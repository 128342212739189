<template>
  <div class="c-appbar">
    <div class="backgorund" :style="{background: setBg()+''}">
      <div v-if="open" class="background-clicker" @click="open = !open" />
      <div class="bar-container" @click.stop>
        <div class="top">
          <div class="logo-edit">
            <div v-if="user.company?.logo?.length === 0" class="logo" @click="$router.push({name:'profile'})">
              <img :src="imgNoLogo" alt="">
            </div>
            <div v-else class="logo">
              <img :src="user.company?.logo" alt="">
            </div>
          </div>
        </div>
        <div class="bottom">
          <n-link v-if="$app.auth.can('users_get')" class="link" :to="{name: 'stats'}" :class="{'active': isActive('stats')}">Статистика</n-link>
          <n-link v-if="$app.auth.can('users_get')" class="link" :to="{name: 'timesheet'}" :class="{'active': isActive('timesheet')}">Табель</n-link>
          <n-link
            v-if="user.company?.successCard === 'bazis' && ($app.auth.can('organizations_get') || $app.auth.can('organizations_get'))"
            class="link" :to="{name: 'organizations'}"
            :class="{'active': isActive('organizations')}" @click="handleReload('organizations')">
            Организации
          </n-link>
          <n-link
            v-if="$app.auth.can('users_get') || $app.auth.can('users_get')"
            class="link" :to="{name: 'users'}" :class="{'active': isActive('users')}"
            @click="handleReload('users')">
            Сотрудники
          </n-link>
          <n-link
            v-if="$app.auth.can('requests_get')"
            class="link" :to="{name: 'applications'}" :class="{'active': isActive('applications')}"
            @click="handleReload('applications')">
            Запросы
          </n-link>
          <n-link
            v-if="$app.auth.can('scheduleTemplates_get')" class="link" :to="{name: 'scheduleTemplates'}"
            :class="{'active': isActive('scheduleTemplates')}"
            @click="handleReload('scheduleTemplates')">
            Расписания
          </n-link>
          <n-link
            v-if="$app.auth.can('users_get')" class="link" :to="{name: 'notifications'}"
            :class="{'active': isActive('notifications')}" @click="handleReload('notifications')">
            Уведомления
          </n-link>
          <!--          <n-link class="link" :to="{name: 'checklists'}" :class="{'active': isActive('checklists')}">Чек-листы</n-link>-->
        </div>
        <div class="info-align" @click.stop>
          <div class="user-info">
            <div class="info-wrapper">
              <div class="logout-button">
                <div class="open-button" @click="open = !open">
                  <img class="icon" :src="imgList" alt="">
                </div>
              </div>
            </div>
            <div v-if="open" class="logout-wrapper" @click.stop>
              <div class="buttons">
                <div
                  v-if="$app.auth.can('companies_edit')||$app.auth.can('roles_create')"
                  class="button " @click="() => {
                    $router.push({name: 'profile'})
                    open = false
                  }">
                  <div class="icon-place">
                    <img class="icon small" :src="imgToProfile" alt="">
                  </div>
                  <div class="title">
                    Настройки компании
                  </div>
                </div>
                <div
                  v-if="$app.auth.can('branches_get') || $app.auth.can('branches_getAll')"
                  class="button " @click="() => {
                    $router.push({name: 'index'})
                    open = false
                  }">
                  <div class="icon-place">
                    <img class="icon small" :src="imgBranches" alt="">
                  </div>
                  <div class="title">
                    Филиалы
                  </div>
                </div>
                <div
                  v-if="$app.auth.can('branches_get') || $app.auth.can('branches_getAll')"
                  class="button " @click="() => {
                    $router.push({name: 'locationPoints'})
                    open = false
                  }">
                  <div class="icon-place">
                    <img class="icon small" :src="imgLocPoint" alt="">
                  </div>
                  <div class="title">
                    Точки контроля
                  </div>
                </div>
                <div
                  v-if="$app.auth.can('departments_get') || $app.auth.can('departments_get')"
                  class="button " @click="() => {
                    $router.push({name: 'departments'})
                    open = false
                  }">
                  <div class="icon-place">
                    <img class="icon small" :src="imgDepartments" alt="">
                  </div>
                  <div class="title">
                    Отделы
                  </div>
                </div>
                <div
                  v-if="$app.auth.can('positions_get')"
                  class="button " @click="() => {
                    $router.push({name: 'positions'})
                    open = false
                  }">
                  <div class="icon-place">
                    <img class="icon small" :src="imgStar" alt="">
                  </div>
                  <div class="title">
                    Должности
                  </div>
                </div>
                <div class="button" @click="$app.auth.logout()">
                  <div class="icon-place">
                    <img class="icon logout" :src="imgLogout" alt="">
                  </div>
                  <div class="title">
                    Выход
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgProfile from 'assets/menu/UserCircle.svg'
import imgNoLogo from 'assets/no-logo-icon.svg'
import imgToProfile from 'assets/menu/gear-solid(1).svg'
import imgLogout from 'assets/menu/right-from-bracket-solid(1).svg'
import imgList from 'assets/menu/List.svg'
import imgLocPoint from 'assets/menu/qrcode-solid.svg'
import imgBranches from 'assets/menu/location-dot-solid.svg'
import imgDepartments from 'assets/menu/people-group-solid(1).svg'
import imgStar from 'assets/menu/address-card-solid.svg'

export default {
  name: 'CAppbar',
  data() {
    return {
      imgProfile,
      imgList,
      imgToProfile,
      imgLogout,
      imgLocPoint,
      imgBranches,
      imgStar,
      imgDepartments,
      open: false,
      user: {},
      imgNoLogo,
    }
  },
  computed: {
    route() {
      return this.$route.name
    },
  },
  created() {
    this.getCompany()
  },
  methods: {
    isActive(name) {
      if (name === 'index' ) {
        return [ 'index', 'branch', ].includes(this.route)
      }
      if (name === 'applications' ) {
        return [ 'applications', ].includes(this.route)
      }
      if (name === 'notifications' ) {
        return [ 'notifications', 'notificationsAdd', ].includes(this.route)
      }
      if (name === 'locationPoints' ) {
        return [ 'locationPoints', 'locationPoint', ].includes(this.route)
      }
      if (name === 'positions' ) {
        return [ 'position', 'positions', ].includes(this.route)
      }
      if (name === 'scheduleTemplates' ) {
        return [ 'scheduleTemplates', 'scheduleTemplate', ].includes(this.route)
      }
      if (name === 'departments') {
        return [ 'departments', 'department', ].includes(this.route)
      }
      if (name === 'applicationTemplates') {
        return [ 'applicationTemplates', 'applicationTemplate', ].includes(this.route)
      }
      if (name === 'timesheet') {
        return this.route === 'timesheet'
      }
      if (name === 'users') {
        return [ 'users', 'user', ].includes(this.route)
      }
      if (name === 'stats') {
        return this.route === 'stats'
      }
      if (name === 'checklists') {
        return [ 'checklists', 'checklist', ].includes(this.route)
      }
      if (name === 'organizations') {
        return [ 'organizations', 'organization', ].includes(this.route)
      }
    },
    handleReload(name) {
      if (name === this.route) {
        this.$router.go(0)
      }
    },
    setBg() {
      return 'linear-gradient(180deg, '+ (localStorage.getItem('colorShadow') || '#57419D') +' 20%, rgba(255, 226, 254, 0) 100%)'
    },
    getCompany() {
      this.$var('load', true)
      const query = 'with[company][with][settings]&with[company][with][integrations]'
      $api.auth.info().query(query).then((response) => {
        this.user = response.data.content.user
        $app.auth.grants(response.data.content.grants)
        $app.auth.user(this.user)
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-appbar {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
  background: #FFFFFF;

  .icon {
    width: 48px;
    height: 48px;

    &.small {
      width: 28px;
      height: 28px;
    }

    &.logout {
      width: 25px;
      height: 25px;
    }
  }


  .background-clicker {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
  }
  .bar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .backgorund {
    padding: 30px 50px;
    height: 108px;
    background: linear-gradient(180deg, var(--primary) 20%, rgba(255, 226, 254, 0) 100%);

  }

  .logout-wrapper {
    position: absolute;
    left: -240px;
    z-index: 100001;
    right: 0;
    top: calc(100% + 10px);
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 4px 4px 0 #00000040;
    .buttons {
      width: 100%;
      .button {
        border-radius: 8px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        padding: 0 10px;

        &:hover {
          background-color: #EBEBEB;
        }
        &:last-child {
          margin-bottom: 10px;
        }
        .icon-place {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          width: 36px;
          height: 36px;
        }
        .title {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  .top {
    margin-right: 20px;
    display: flex;
    .logo-edit {
      display: flex;
      align-items: center;
      .logo {
        height: 40px;
        width: 200px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

  }

  .info-align {
    .user-info {
      border-radius: 26px;
      position: relative;

      .info-wrapper {
        width: 100%;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .open-button {
          svg {
            path {
              fill: var(--primary);
            }
          }
        }
        &.logout {
          margin-top: 10px;
          justify-content: center;
        }
      }

      .info {
        z-index: 10000001;
        color: #767676;
        font-size: 0.8em;
        margin-right: 10px;

        div:first-child {
          font-size: 1.2em;
          color: #000000;
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .link {
      margin-right: 20px;
      margin-bottom: 5px;
      color: #000000;
      cursor: pointer;
      flex-shrink: 0;
      min-width: 125px;
      padding: 5px;
      min-height: 40px;
      font-size: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid var(--primary);
      border-radius: 24px;
      background-color: #FFFFFF;
      &.active {
        color: #ffffff;
        background-color: var(--primary);
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
