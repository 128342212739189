import CQr from 'components/qr/Qr'
import CAppbar from 'components/appbar/Index'
import CAddressSelect from 'components/address-select/Index'
import CMyCalendar from 'components/my-calendar/Index.vue'
import CCalendar from 'components/calendar/Index.vue'
import CProgressBar from 'components/progress-bar/Index.vue'
import CFilter from 'components/filter/Index.vue'
import CNewCheckpoint from 'components/new-checkpoint/Index.vue'
import CCheckpointSheet from 'components/c-checkpoint-sheet/Index.vue'
import CAddVisit from 'components/add-visit/Index.vue'
import CDatePicker from 'components/date-picker/Index.vue'
import CEditVisit from 'components/edit-visit/Index.vue'
import CModal from 'components/modal-message/Index.vue'
import CPagination from 'components/pagination/Index.vue'
import CImporter from 'components/importer/Index.vue'
import CExporter from 'components/exporter/Index.vue'
import CApplySchedule from 'components/apply-schedule/Index.vue'
import CFireEmployee from 'components/fire-employee/Index.vue'
import CCreateRole from 'components/create-role/Index.vue'
import CAttachRole from 'components/attach-role/Index.vue'
import CShowVisitOnMap from 'components/show-visit-on-map/Index.vue'
import CShowVisitsOnMap from 'components/show-visit-on-map-by-date/Index.vue'
import CEditBreak from 'components/edit-break/Index.vue'
import CAddBreak from 'components/add-break/Index.vue'

export default {
  CQr,
  CAppbar,
  CAddressSelect,
  CMyCalendar,
  CCalendar,
  CProgressBar,
  CFilter,
  CNewCheckpoint,
  CCheckpointSheet,
  CAddVisit,
  CDatePicker,
  CEditVisit,
  CModal,
  CPagination,
  CImporter,
  CExporter,
  CApplySchedule,
  CFireEmployee,
  CCreateRole,
  CAttachRole,
  CShowVisitOnMap,
  CShowVisitsOnMap,
  CEditBreak,
  CAddBreak,
}

